/**
 * CoverInfo
 * 
 * @Author: Focci
 * @Date: 2023-11-15 16:59:27
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-15 16:59:27
 */

import PropTypes from 'prop-types'
import PropertyChips from '@page/common/card/property/Chips'
import Image from 'next/image'
import clsx from 'clsx'

export default function CoverInfo({
  house,
  className = 'aspect-[300 / 225]',
  children
}) {
  return (
    <div className={clsx(['relative', className])}>
      <Image
        priority
        src={house.cover || ''}
        alt={house.address}
        width={300}
        height={225}
        className="object-cover w-full h-full"
      />
      <div 
        className="
          text-white absolute inset-0 top-auto  
          p-2 z-1 bg-image-mask-sm
        "
      >
        <span className="text-medium text-f.8">
          {house.priceLabel}
        </span>
        {children}
      </div>
      <div className="absolute z-1 left-2 top-2">
        <PropertyChips {...house} />
      </div>
    </div>
  )
}

CoverInfo.propTypes = {
  house: PropTypes.object,
}
