/**
 * AgentInfo
 * 
 * @Author: Focci
 * @Date: 2023-11-15 16:47:46
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-15 16:47:46
 */

import PropTypes from 'prop-types'
import Image from 'next/image'
// import clsx from 'clsx'
import { isArray, map } from 'lodash-es'

export default function AgentInfo({ agents, children }) {
  if(!(isArray(agents) && agents.length > 0)) {
    return null
  }

  const agentName = map(agents, 'name').join(' & ')

  return (
    <div className="px-2">
      <div className="flex items-center gap-x-0.5">
        {agents.map((agent) => (
          <Image
            priority
            key={agent.name}
            src={agent.icon || ''}
            alt={agent.name}
            width={50}
            height={50}
            className="w-10 h-10 rounded-full object-cover object-top border border-white"
          />
        ))}
      </div>
      <div className="flex items-center justify-between">
        <div className="mt-1 text-f.6">
          <span>{agentName}</span>
        </div>
        {children}
      </div>
    </div>
  )
}

AgentInfo.propTypes = {
  agents: PropTypes.array,
}
