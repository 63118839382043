/**
 * PropertyIcons(房间数、浴室数、车位)
 * 
 * @Author: Focci
 * @Date: 2023-07-19 15:28:32
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-19 15:28:32
 */

import PropTypes from 'prop-types'
import SVGBedroom from '@img/bedroom.svg'
import SVGBathroom from '@img/bathroom.svg'
import SVGCarspace from '@img/carspace.svg'
import SVGFloorArea from '@img/floor-area.svg'
import SVGLandArea from '@img/land-area.svg'
import clsx from 'clsx'

export default function PropertyIcons({
  data = {},
  iconWidth = '.9rem',
  className = 'gap-2',
  children
}) {
  return (
    <div 
      className={clsx([
        'flex items-center mt-0.5 overflow-hidden',
        className
      ])}
    >
      {!!data.bedrooms && data.bedrooms !== '0' && (
        <div className="flex items-center leading-none">
          <SVGBedroom width={iconWidth} />
          <span className="ml-1">{data.bedrooms}</span>
        </div>
      )}
      {!!data.bathrooms && data.bathrooms !== '0' && (
        <div className="flex items-center leading-none">
          <SVGBathroom width={iconWidth} />
          <span className="ml-1">{data.bathrooms}</span>
        </div>
      )}
      {!!data.carspaces && data.carspaces !== '0' && (
        <div className="flex items-center leading-none">
          <SVGCarspace width={iconWidth} />
          <span className="ml-1">{data.carspaces}</span>
        </div>
      )}
      {!!data.floorarea && data.floorarea !== '0' && (
        <div className="flex items-center leading-none">
          <SVGFloorArea width={iconWidth} />
          <span className="ml-1">
            {`${data.floorarea}m`}
            <sup>2</sup>
          </span>
        </div>
      )}
      {!!data.landarea && data.landarea !== '0' && (
        <div className="flex items-center">
          <SVGLandArea width={iconWidth} />
          <span className="ml-1">
            {`${data.landarea}m`}
            <sup>2</sup>
          </span>
        </div>
      )}
      {children}
    </div>
  )
}

PropertyIcons.propTypes = {
  data: PropTypes.object,
  iconWidth: PropTypes.string,
}
