/**
 * Property Chip
 * 
 * @Author: Focci
 * @Date: 2023-07-19 16:51:54
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-19 16:51:54
 */

import clsx from 'clsx'
import { isArray } from 'lodash-es'
import { useTranslations } from 'next-intl'

function PropertyChip({
  showColorDot = true,
  color = 'bg-primary',
  label = '',
  className = 'rounded-full bg-white text-c3b'
}) {
  return (
    <div 
      className={clsx([
        'text-f.6 py-0.5 px-2',
        'flex items-center gap-1 pointer-events-none',
        className,
      ])}
    >
      {showColorDot && <span className={clsx(['w-2 h-2 rounded-full inline-block', color])} />}
      <span className="line-clamp-1">{label}</span>
    </div>
  )
}

export default function PropertyChips({
  openHomeLabel = '',
  newListing = false,
  newHomes = false,
  nolisting = false,
  sold = false,
  onSale = false,
}) {
  const th = useTranslations('house')
  const ts = useTranslations('search')

  return (
    <div className="flex flex-wrap gap-1">
      {openHomeLabel && <PropertyChip color="bg-success" label={openHomeLabel} />}
      {newListing && <PropertyChip color="bg-error" label={th('newListing')} />}
      {newHomes && <PropertyChip color="bg-warning" label={th('newHomes')} />}
      {nolisting && <PropertyChip color="bg-helper" label={th('nolisting')} />}
      {sold && <PropertyChip color="bg-error" label={th('recentlySold')} />}
      {onSale && <PropertyChip color="bg-primary" label={ts('listingForSale')} />}
    </div>
  )
}

export function PropertyTags({ data = [] }) {
  return (
    <div className="flex flex-wrap gap-1">
      {isArray(data) && data.map((tag) => (
        <PropertyChip
          key={tag}
          className="border border-[#c8c8c8] rounded-sm font-medium" 
          showColorDot={false} 
          label={tag}
        />
      ))}
    </div>
  )
}
