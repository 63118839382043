import { getHouseData } from '@comp/page/property/get-house-data'
import { isArray, map } from 'lodash-es'
import { propertyDetail } from '@lib/route/house'

export function getAdData(data, locale) {
  const house = getHouseData(data.house)
  const { office } = house
  const agents = isArray(house.agents) ? house.agents : []
  const url = propertyDetail(house.slug, locale)
  const agentName = map(agents, 'name').join(' & ')

  return {
    house,
    office,
    url,
    agents,
    agentName,
  }
}
