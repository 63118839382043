/**
 * User Tracking
 * 
 * @Author: Focci
 * @Date: 2023-10-24 17:20:01
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-24 17:20:01
 */

'use client'

import PropTypes from 'prop-types'
import useTracking from '@hook/useTracking'
import { useEffect } from 'react'
import { isAU } from '@lib/utils'

export default function UserTracking({
  eventName = '',
  once = false,
  auOnly = false,
  enabledGA4, // 是否开启 ga4统计
  enableApi, // 是否开启 api 统计
  params,
}) {
  const { pushTrack } = useTracking()

  useEffect(() => {
    if(auOnly) {
      isAU && pushTrack(eventName, params, { once, enabledGA4, enableApi })
    } else {
      pushTrack(eventName, params, { once, enabledGA4, enableApi })
    }
  }, [pushTrack, eventName, params, once, auOnly, enabledGA4, enableApi])
}

UserTracking.propTypes = {
  className: PropTypes.string,
  params: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}
