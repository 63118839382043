/**
 * 用户事件跟踪
 * 
 * @Author: Focci
 * @Date: 2023-10-24 15:38:22
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-24 15:38:22
 */

import Cookies from 'js-cookie'
import { UUID_PROP } from '@lib/config'
import { fetchPost } from '@lib/fetch/client'
import { generateUrl, isWindow } from '@lib/utils'
import { map, isArray, forEach, size } from 'lodash-es'
import { useCallback, useRef } from 'react'
import { getAbsoluteUrl } from '@lib/fetch/lib'
import { 
  getViewHouseParam,
  getHouseSearchParam,
  getFavoriteAndShareData,
  getListingData,
} from '@comp/user-tracking/lib'
import useLogin from './useLogin'
import useApp from './useApp'

function getKey(eventName, params) {
  let key = eventName
  try{ key += JSON.stringify(params) } catch(err) { /**/ }

  return key
}

function getTrackParam(param, user, eventName) {
  const { id: userId, uuid } = user || {}
  const globalParam = eventName.indexOf('homely') === 0
    ? { userId: userId || 0, uuid: uuid || 0 }
    // 新版本统计事件公共参数
    // https://docs.google.com/spreadsheets/d/1SWkDqeTKy5AW8PNkQgr8YR5VFPe9KP7fUQ72LV6lHwY/edit?pli=1#gid=0
    : {
      is_logged_in: !!user,
      user_id: user?.id || '',
      uuid: user?.uuid || Cookies.get(UUID_PROP) || '',
      country: String(process.env.NEXT_PUBLIC_COUNTRY).toLowerCase(),
    }

  if(isArray(param)) {
    return map(param, (item) => ({ ...item, ...globalParam }))
  }

  return [{ ...(param || {}), ...globalParam }]
}

/**
 * 添加 track
 * @param {Object} user 用户信息
 * @param {String} eventName 事件名称
 * @param {Object|Array} param 参数
 * @param {Boolean} enableApi 是否需要请求接口调用
 * @param {Boolean} enabledGA4 是否开启ga4统计
 * 
 */
function pushTrackCallback({ 
  user, 
  eventName, 
  params, 
  enableApi = true,
  enabledGA4 = false,
}) {
  if(!isWindow) return

  const trackParam = getTrackParam(params, user, eventName)
  
  if(enabledGA4) {
    forEach(trackParam, (query) => {
      isWindow && window.dataLayer && window.dataLayer.push({
        event: eventName,
        ...query
      })
    })
  }

  if(enableApi) {
    let json = {}
    try{ json = JSON.stringify(trackParam) } catch(err) { /**/ }

    fetchPost(`record-event/${eventName}`, { json })
  }
}

function getRealPushParam(params, cfg) {
  // eslint-disable-next-line no-underscore-dangle
  const extra = params?.__extra__
  const res = params

  // category
  if(res?.property_types && cfg?.subType?.[extra?.categoryId]) {
    res.property_types = cfg.subType[extra.categoryId]
  }
  // districtId
  if(res?.district_name && cfg?.district?.[extra?.districtId]) {
    res.district_name = cfg.district[extra.districtId]
  }
  // priceMethodId
  if(res?.price_method && cfg?.priceMethod?.[extra?.priceMethodId]) {
    res.price_method = cfg.priceMethod[extra.priceMethodId]
  }
  // regionId
  if(res?.region_name && cfg?.region?.[extra?.regionId]) {
    res.region_name = cfg.region[extra.regionId]
  }
  // suburbId
  if(res?.suburb_name && cfg?.suburb?.[extra?.suburbId]) {
    res.suburb_name = cfg.suburb[extra.suburbId]
  }
 
  return res
}

export default function useTracking() {
  const { user } = useLogin()
  const { evtcfg } = useApp()
  const storage = useRef({})
  
  const pushTrack = useCallback((eventName, params, option = {}) => {
    const realParam = getRealPushParam(params, evtcfg)

    if(!size(realParam)) return

    const { once, ...opt } = option || {}
    
    if(once) {
      const key = getKey(eventName, realParam)
      if(!storage.current[key]) {
        pushTrackCallback({ ...opt, user, eventName, params: realParam })
        storage.current[key] = true
      }
    } else {
      pushTrackCallback({ ...opt, user, eventName, params: realParam })
    }
  }, [user, evtcfg])

  /**
   * 搜索
   * @param {Object} query 如果不传，则不会调用自已提供的接口统计接口
   */
  const pushTrackSearch = useCallback((searchParam, query, isMapListing = true) => {
    if(searchParam) {
      const aparam = getHouseSearchParam(searchParam, isMapListing, evtcfg)
      pushTrack('house_search', aparam, { once: true, enabledGA4: true, enableApi: false })
    }
    if(query) {
      const baseApiUrl = getAbsoluteUrl('houses/filter')
      const value = generateUrl(baseApiUrl, query)
      pushTrack('house_search', { value }, { once: true })
    }
  }, [pushTrack, evtcfg])

  /**
   * 房源详情公共push函数
   */
  const pushTrackListing = useCallback((
    eventName,
    house, 
    option = { once: true, enabledGA4: true, enableApi: false }
  ) => {
    if(house) {
      const aparam = getListingData(house)
      pushTrack(eventName, aparam, option)
    }
  }, [pushTrack])

  /**
   * 房源详情点击事件
   */
  const pushTrackListingClick = useCallback((clickType, house) => {
    if(house && clickType) {
      pushTrack(
        'house_page_click', 
        { ...getListingData(house), click_point: clickType }, 
        { once: true, enabledGA4: true, enableApi: false }
      )
    }
  }, [pushTrack])

  /**
   * 浏览图片
   */
  const pushTrackViewImage = useCallback((house) => {
    if(house) {
      const aparam = getViewHouseParam(house)
      pushTrack('view_image', aparam, { once: true, enabledGA4: true, enableApi: false })
    }
  }, [pushTrack])

  /**
   * 收藏
   */
  const pushTrackFavorite = useCallback((house, eventName = 'add_favourite') => {
    if(house) {
      const aparam = getFavoriteAndShareData(house)
      pushTrack(eventName, aparam, { once: true, enabledGA4: true, enableApi: false })
    }
  }, [pushTrack])

  /**
   * 分享
   */
  const pushTrackShare = useCallback((eventName, house) => {
    if(house) {
      const aparam = getFavoriteAndShareData(house)
      pushTrack(eventName, aparam, { once: true, enabledGA4: true, enableApi: false })
    }
  }, [pushTrack])

  return {
    pushTrack,
    pushTrackSearch,
    pushTrackViewImage,
    pushTrackFavorite,
    pushTrackListing,
    pushTrackListingClick,
    pushTrackShare
  }
}
